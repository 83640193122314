import dayjs from 'dayjs';

import { TranslateFunc } from '@core/utils/locale';
import { ExportColumnHeadersEnum, LocalesEnum, PhrasesEnum } from '@core/enums/localeEnum';

type SocialMediaQuery = {
    name: string;
    hasRating: boolean;
    translationKey?: PhrasesEnum;
};
export function getExportTimestamp(): string {
    return dayjs(new Date()).format('YYYY_MM_DD_HH_mm_ss');
}

export function buildQuery(
    filters: string,
    locale: LocalesEnum,
    t: TranslateFunc,
    clientExternalIdType: string,
    isTagsExportEnabled: boolean,
    isCustomerStatusExportEnabled: boolean,
) {
    return `
        query exportOperators {
            search_details(filter: ${filters}, locale: "${locale}") {
                operators {
                    DS_ID: operator_identifier
                    DS_URL: ds_url
                    ${t(ExportColumnHeadersEnum.ENTITY_TYPE)}: entity_type_localized
                    ${t(ExportColumnHeadersEnum.CONFIDENCE_LEVEL)}: confidence_level_localized
                    ${t(ExportColumnHeadersEnum.OPERATOR_STATUS)}: operator_status_localized
                    ${t(ExportColumnHeadersEnum.BUSINESS_NAME)}: business_name
                    ${t(ExportColumnHeadersEnum.PHONE)}: phone
                    ${t(ExportColumnHeadersEnum.WEBSITE)}: website
                    SEGMENT_CD: segment_code
                    SEGMENT_DESC: segment
                    ${t(ExportColumnHeadersEnum.SUB_CHANNEL)}: sub_channel
                    ${t(ExportColumnHeadersEnum.CHANNEL)}: channel

                    location {
                        ${t(ExportColumnHeadersEnum.COUNTRY)}: country_localized
                        ${t(ExportColumnHeadersEnum.ADDRESS_1)}: address_1
                        ${t(ExportColumnHeadersEnum.ADDRESS_2)}: address_2
                        ${t(ExportColumnHeadersEnum.ADDRESS_3)}: address_3
                        ${t(ExportColumnHeadersEnum.CITY)}: locality_1
                        ${t(ExportColumnHeadersEnum.ADMINISTRATIVE_AREA_1)}: administrative_area_1
                        ${t(ExportColumnHeadersEnum.ADMINISTRATIVE_AREA_2)}: administrative_area_2
                        ${t(ExportColumnHeadersEnum.ADMINISTRATIVE_AREA_3)}: administrative_area_3
                        ${t(ExportColumnHeadersEnum.ADMINISTRATIVE_AREA_4)}: administrative_area_4
                        ${t(ExportColumnHeadersEnum.POSTAL_CODE_1)}: postal_code_1
                        LATITUDE: latitude_localized
                        LONGITUDE: longitude_localized
                    }

                    ${t(ExportColumnHeadersEnum.LEGAL_NAME)}: legal_name
                    ${t(ExportColumnHeadersEnum.ID_BUSINESS_DETAILED)}: business_detail_id
                    ${t(ExportColumnHeadersEnum.ID_BUSINESS_GENERAL)}: business_general_id
                    # DATE_FOUNDED: date_founded

                    ${buildRangeFieldsString(t, [ExportColumnHeadersEnum.YEARS_IN_BUSINESS_RANGE, ExportColumnHeadersEnum.NUMBER_OF_EMPLOYEES_RANGE, ExportColumnHeadersEnum.ANNUAL_SALES_RANGE])}

                    ${t(ExportColumnHeadersEnum.OPERATING_HOURS)}: operating_hours_raw

                    # ${t(ExportColumnHeadersEnum.HOURS_MONDAY)}:hours_sunday
                    # ${t(ExportColumnHeadersEnum.HOURS_TUESDAY)}:hours_monday
                    # ${t(ExportColumnHeadersEnum.HOURS_WEDNESDAY)}:hours_tuesday
                    # ${t(ExportColumnHeadersEnum.HOURS_THURSDAY)}:hours_wednesday
                    # ${t(ExportColumnHeadersEnum.HOURS_FRIDAY)}:hours_thursday
                    # ${t(ExportColumnHeadersEnum.HOURS_SATURDAY)}:hours_friday
                    # ${t(ExportColumnHeadersEnum.HOURS_SUNDAY)}:hours_saturday

                    parent_chain {
                        ${t(ExportColumnHeadersEnum.CHAIN_SYSTEM_ID)}: operator_identifier
                        ${t(ExportColumnHeadersEnum.CHAIN_SYSTEM_NAME)}: business_name
                    }

                    ${buildRangeFieldsString(t, [ExportColumnHeadersEnum.CHAIN_SIZE_RANGE])}

                    parent_mco {
                        MCO_ID: operator_identifier
                        ${t(ExportColumnHeadersEnum.MCO_NAME)}: business_name
                    }

                    host {
                        id
                        ${t(ExportColumnHeadersEnum.HOST_ID)}: operator_identifier
                        ${t(ExportColumnHeadersEnum.HOST_NAME)}: business_name
                    }

                    ${t(ExportColumnHeadersEnum.HOST_NUMBER_OF_UNITS_LOCATED_WITHIN)}: host_number_of_units_location_within

                    CUISINE_CD: cuisine_code
                    ${t(ExportColumnHeadersEnum.CUISINE_PRIMARY_DESC)}: cuisine
                    ${t(ExportColumnHeadersEnum.CUISINE_SECONDARY_DESC)}: sub_cuisine

                    ${buildRangeFieldsString(t, [ExportColumnHeadersEnum.ESTIMATED_MEALS_PER_DAY_RANGE, ExportColumnHeadersEnum.AVERAGE_CHECK_RANGE])}
                    
                    ${t(ExportColumnHeadersEnum.OFFERS_DELIVERY)}: offers_delivery_localized
                    ${t(ExportColumnHeadersEnum.OFFERS_TAKEOUT)}: offers_takeout_localized

                    ${buildRangeFieldsString(t, [ExportColumnHeadersEnum.NUMBER_OF_BEDS_RANGE])}
                    
                    ${t(ExportColumnHeadersEnum.LODGING_STAR_LEVEL)}: lodging_star_level_localized
                    
                    ${buildRangeFieldsString(t, [ExportColumnHeadersEnum.NUMBER_OF_ROOMS_RANGE])}

                    ${t(ExportColumnHeadersEnum.NON_COMMERCIAL_SECTOR)}: non_commercial_sector_localized
                    ${t(ExportColumnHeadersEnum.EDUCATION_LEVELS)}: education_grade_levels_localized

                    ${t(ExportColumnHeadersEnum.ANNUAL_SALES)}: annual_sales
                    ${t(ExportColumnHeadersEnum.ANNUAL_SALES_TYPE)}: annual_sales_type_localized

                    ${buildRangeFieldsString(t, [ExportColumnHeadersEnum.NUMBER_OF_STUDENTS_RANGE])}

                    ${buildSocialMediaFields(t, SOCIAL_MEDIAS_1)}

                    delivery {
                        type
                        ${t(ExportColumnHeadersEnum.DELIVERY_LINKS)}: url
                    }

                    ${t(ExportColumnHeadersEnum.RESERVATION_LINKS)}: reservation_links

                    ${buildSocialMediaFields(t, SOCIAL_MEDIAS_2)}

                    ${t(ExportColumnHeadersEnum.POPULARITY_SCORE)}: popularity_score

                    ${buildPurchasePotentialFieldsString(t, [
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_SUM_TOTAL,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_SUM_FOOD,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_SUM_BEVERAGE,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_SUM_FOOD_AND_BEVERAGE,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_DISPOSABLE,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_DRY,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FRESH,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FROZEN,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_NON_ALCOHOL,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_ALCOHOL,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FRESH_PRODUCE,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FRESH_DAIRY,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FRESH_EGGS,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FRESH_RAW_MEAT_AND_SEAFOOD,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FRESH_PREPARED,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FROZEN_PRODUCE,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FROZEN_RAW_MEAT_AND_SEAFOOD,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_FROZEN_PREPARED,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_DRY_OIL,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_DRY_CANNED,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_DRY_SEASONING,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_DRY_OTHER_PANTRY,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_ALCOHOL_SPIRITS,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_ALCOHOL_WINE,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_ALCOHOL_BEER,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_NON_ALCOHOL_SOFT_DRINKS,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_NON_ALCOHOL_COFFEE,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_NON_ALCOHOL_BOTTLED_WATER,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_WINE_RED_WINE,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_WINE_WHITE_WINE,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_WINE_ROSE,
                        ExportColumnHeadersEnum.PURCHASE_POTENTIAL_WINE_CHAMPAGNE,
                    ])}

                     ${isTagsExportEnabled ? buildTagsFieldsString(t, [ExportColumnHeadersEnum.TAGS_FOOD, ExportColumnHeadersEnum.TAGS_PLACE]) : ''}

    

                    ${isCustomerStatusExportEnabled ? buildCompanyDataFieldsString(t, clientExternalIdType) : ''}

                }

                _metadata {
                    page
                    size
                    page_count
                    total_count
                }
            }
        }`;
}

function buildRangeFieldsString(t: TranslateFunc, rangeFields: ExportColumnHeadersEnum[]) {
    const rangeFieldsString = rangeFields
        .map((rangeField) => {
            return `${rangeField}__${t(rangeField)}_CD: unified_code
            ${rangeField}__${t(rangeField)}_DESC: description`;
        })
        .join('\n');

    return `
        ranges {
            type
            ${rangeFieldsString}
        }`;
}

const SOCIAL_MEDIAS_1: SocialMediaQuery[] = [
    { name: 'FACEBOOK', hasRating: true },
    { name: 'YELP', hasRating: true },
    { name: 'GOOGLE', hasRating: true },
    { name: 'FOURSQUARE', hasRating: false },
    { name: 'INSTAGRAM', hasRating: false },
    { name: 'TRIPADVISOR', hasRating: true },
];

const SOCIAL_MEDIAS_2: SocialMediaQuery[] = [
    { name: 'MICHELIN', hasRating: false },
    { name: 'BOOKING_DOT_COM', hasRating: false, translationKey: ExportColumnHeadersEnum.BOOKING_DOT_COM },
    { name: 'HOTELS_DOT_COM', hasRating: false, translationKey: ExportColumnHeadersEnum.HOTELS_DOT_COM },
];

function buildSocialMediaFields(t: TranslateFunc, socialMediaList: SocialMediaQuery[]) {
    const socialMediasquery = socialMediaList
        .map((sm) => {
            if (sm.hasRating) {
                return `
            ${sm.name}__URL_${sm?.translationKey ? t(sm.translationKey) : sm.name}: url
            ${sm.name}__${sm.name}_${t(ExportColumnHeadersEnum.REVIEW_RATING)}: average_rating_localized
            ${sm.name}__${sm.name}_${t(ExportColumnHeadersEnum.REVIEW_COUNT)}: total_reviews_localized`;
            }

            return `
            ${sm.name}__URL_${sm?.translationKey ? t(sm.translationKey) : sm.name}: url `;
        })
        .join('');

    return `
        social_media {
            ${socialMediasquery}
            type
        }`;
}

function buildPurchasePotentialFieldsString(t: TranslateFunc, purchasePotentialFields: ExportColumnHeadersEnum[]) {
    const purchasePotentialFieldsString = purchasePotentialFields
        .map((purchasePotentialField) => {
            return `${purchasePotentialField}__${t(purchasePotentialField)}: value_localized`;
        })
        .join('\n');

    return `
        purchase_potential {
            name
            ${purchasePotentialFieldsString}
        }`;
}

function buildTagsFieldsString(t: TranslateFunc, tagsFields: ExportColumnHeadersEnum[]) {
    const typeMapping: { [key in ExportColumnHeadersEnum]?: string } = {
        [ExportColumnHeadersEnum.TAGS_FOOD]: 'FOOD',
        [ExportColumnHeadersEnum.TAGS_PLACE]: 'PLACES',
    };

    const tagsFieldsString = tagsFields.map((tagsField) => `${typeMapping[tagsField]}__${t(tagsField)}`).join('__');

    return `
        tags {
            type
            ${tagsFieldsString}: description
        }`;
}

function buildCompanyDataFieldsString(t: TranslateFunc, clientExternalIdType: string) {
    if (clientExternalIdType) {
        return `
        company_data {
            ${t(ExportColumnHeadersEnum.CLIENT_STATUS)}: status
        }`;
    }

    return '';
}
