import {
    IconAdminCog,
    IconAdminCogActive,
    IconHome,
    IconHomeActive,
    IconOperator,
    IconOperatorActive,
    IconSavedSearchNav,
    IconSavedSearchNavActive,
    IconTerritories,
    IconTerritoriesActive,
} from '@assets/icons';
import { RoutesEnum } from '@enums/RoutesEnum';
import { SidebarPhrasesEnum } from '@enums/localeEnum';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';

import { useAppContext } from '../../../App.context';

export interface INavLink {
    link: RoutesEnum;
    sublinks?: RoutesEnum[];
    title: SidebarPhrasesEnum;
    Icon: React.FC<React.SVGProps<SVGSVGElement>>;
    IconActive: React.FC<React.SVGProps<SVGSVGElement>>;
    testId: string;
}

export const useNavLinks = () => {
    const {
        isAdmin,
        hasTerritoryAccess
    } = useAppContext();

    const isTerritoriesPageEnabled = useFeatureFlag(FeatureFlags.TerritoriesPage);
    const isAdminTerritoryPageEnabled = useFeatureFlag(FeatureFlags.AdminTerritoryPage);

    const navLinks: INavLink[] = [
        {
            link: RoutesEnum.Home,
            title: SidebarPhrasesEnum.Home,
            Icon: IconHome,
            IconActive: IconHomeActive,
            testId: 'buttonNavbarHome',
        },
        {
            link: RoutesEnum.OperatorTargetingCriteria,
            sublinks: [RoutesEnum.OperatorProfile, RoutesEnum.OperatorTargetingResults],
            title: SidebarPhrasesEnum.OperatorTargeting,
            Icon: IconOperator,
            IconActive: IconOperatorActive,
            testId: 'buttonNavbarOperatorTargeting',
        },
        ...(isTerritoriesPageEnabled && hasTerritoryAccess
            ? [
                {
                    link: RoutesEnum.Territories,
                    title: SidebarPhrasesEnum.Territories,
                    Icon: IconTerritories,
                    IconActive: IconTerritoriesActive,
                    testId: 'buttonNavbarTerritories',
                },
            ]
            : []),
        {
            link: RoutesEnum.SavedSearches,
            title: SidebarPhrasesEnum.SavedSearches,
            Icon: IconSavedSearchNav,
            IconActive: IconSavedSearchNavActive,
            testId: 'buttonNavbarSavedSearches',
        },
    ];

    const adminNavLinks: INavLink[] = [
        {
            link: RoutesEnum.TerritoryAdmin,
            title: SidebarPhrasesEnum.Admin,
            Icon: IconAdminCog,
            IconActive: IconAdminCogActive,
            testId: 'buttonNavbarAdmin',
        },
    ];

    return { navLinks, adminNavLinks: isAdmin && isAdminTerritoryPageEnabled ? adminNavLinks : [] };
};
